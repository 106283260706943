import { TypeAnimation } from "react-type-animation";
import finance from "./../images/finance.png";
import ser from "./../images/image-removebg-preview (1).png";
import BlueDash from "./blue-dash";

const AboutUsSection = () => {
  return (
    <div id="about">
      <div className="flex pt-16 lg:pt-44 pb-10 gap-16 items-center">
        <div className="flex flex-col">
          <p className="text-sm lg:text-2xl  font-semibold mb-2 text-blue-600 text-right">
            معلومات عنا
          </p>
          <p className=" text-2xl lg:text-4xl font-medium text-right">
            في ResumeByte، نحن شغوفون بـ
            <br />
            <TypeAnimation
              sequence={["مساعدة", 1000, "مهنة", 2000]}
              wrapper="span"
              cursor={true}
              repeat={Infinity}
              className={
                " text-xl lg:text-xl text-blue-500"
              }
            />
          </p>
          <BlueDash classname="!ml-[28vmax]" />
          <p className="text-right">
            تقنيتنا المتطورة تحسن سيرتك الذاتية لتناسب نظام تتبع المتقدمين
            (ATS)، كتابنا ذوو الخبرة يعرفون ما يريده أصحاب العمل، وخدمتنا
            الشخصية تفصل عروضنا لتناسب أهدافك المهنية لزيادة النجاح.
          </p>
        </div>
        <img
          src={ser}
          alt="finance"
          className=" serpic bounce absolute right-0 opacity-25  w-1/2 sm:w-64 lg:opacity-100 lg:w-72 lg:relative xl:w-1/3"
        />
      </div>
    </div>
  );
};

export default AboutUsSection;

import ZigZag from "./../../images/line-process.png";
import ProcessBox from "./process-box";
import { BiEdit } from "react-icons/bi";

const ProcessMain = () => {
  const iconClasses = "h-14 w-14 z-50 -rotate-45 text-sky-50";
  const process = [
    {
      title: "املأ النموذج",
      anchor: "املأ النموذج",
      link: "https://google.com",
      info: "أدخل تفاصيلك وتفضيلاتك الوظيفية لبدء بناء سيرتك الذاتية.",
      icon: <BiEdit className={iconClasses} />,
    },
    {
      title: "اختر القالب",
      anchor: "اختر القالب",
      link: "https://google.com",
      info: "اختر قالبًا احترافيًا يناسب أسلوبك بشكل أفضل.",
      icon: <BiEdit className={iconClasses} />,
    },
    {
      title: "تحميل السيرة الذاتية",
      anchor: "تحميل السيرة الذاتية",
      link: "https://google.com",
      info: "احصل على سيرتك الذاتية جاهزة للتنزيل في بضع نقرات فقط.",
      icon: <BiEdit className={iconClasses} />,
    },
    {
      title: "هل لديك مشاكل؟",
      anchor: "هل لديك مشاكل؟",
      link: "https://google.com",
      info: "تواصل مع فريق الدعم لدينا للحصول على أي مساعدة في إنشاء السيرة الذاتية.",
      icon: <BiEdit className={iconClasses} />,
    },
  ];
  // process-bg before:content-[''] before:absolute before:w-full before:h-full before:top-0 before:left-0

  return (
    <div className={`relative process-bg `}>
      {/* <img
        src={ZigZag}
        alt="zigzag"
        className=" hidden xl:block absolute top1/2 left-1/2 -translate-x-1/2 translate-y-12 w-[85%]"
      /> */}
      <div className="flex items-center justify-around gap-[3.8vw] lg:flex-nowrap flex-wrap bordbox">
        {process.map(({ title, link, info, icon, anchor }, i) => (
          <ProcessBox
            key={i}
            title={title}
            link={link}
            info={info}
            step={i + 1}
            icon={icon}
            anchor={anchor}
            zigzag={i % 2 === 0}
          />
        ))}
      </div>
    </div>
  );
};

export default ProcessMain;

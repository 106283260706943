import React, { useEffect } from "react";
import "./ProAcc.css";
import BlueDash from "../blue-dash";
import { BiEdit } from "react-icons/bi";
import { Link } from "react-router-dom";
import signup from "../../images/form/signup.png";
import create from "../../images/form/create.png";
import download from "../../images/form/download.png";
import AOS from "aos";
import "aos/dist/aos.css";
function ProAcc() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="backfooter process">
      <div className="container ">
        <div className="py-5 row txt d-flex justify-content-center">
          <div className="pt-4 col-lg-8 col-md-12 col-sm-12">
            <h1 className="text-right my-[2vh]">
              إنشاء سير ذاتية مثالية لسوق العمل الحديث
            </h1>
            <p className="text-right">
              إنشاء سيرة ذاتية لم يكن بهذه السهولة من قبل! في ثلاث خطوات بسيطة،
              أنشئ الوثيقة المثالية لإبهار مديري التوظيف وأصحاب العمل. أقل وقت،
              أقصى جودة احترافية.
            </p>
            <button className="p-3 text-white bluebg ml-[35vw]">
              <Link to="/build-resume" className="text-white">
                أنشئ سيرتي الذاتية
              </Link>
            </button>
          </div>
        </div>
        <div className="row whitebg ">
          <div className="row">
            <ul
              className="overflow-hidden nav nav-tabs w-75 "
              id="myTab"
              role="tablist"
            >
              <li
                className="nav-item col-lg-4 col-md-4 col-sm-4 "
                role="presentation"
              >
                <button
                  className="nav-link active "
                  id="home-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#home-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="home-tab-pane"
                  aria-selected="true"
                >
                  1. التسجيل
                  <BlueDash marginY="my-1 mb-1" />
                </button>
              </li>

              <li
                className="nav-item col-lg-4 col-md-4 col-sm-4 "
                role="presentation"
              >
                <button
                  className="nav-link"
                  id="profile-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#profile-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="profile-tab-pane"
                  aria-selected="false"
                >
                  2. أنشئ
                  <BlueDash marginY="my-1 mb-1" />
                </button>
              </li>

              <li
                className="nav-item col-lg-4 col-md-4 col-sm-4 "
                role="presentation"
              >
                <button
                  className="nav-link"
                  id="contact-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#contact-tab-pane"
                  type="button"
                  role="tab"
                  aria-controls="contact-tab-pane"
                  aria-selected="false"
                >
                  3. تحميل
                  <BlueDash marginY="my-1 mb-1" />
                </button>
              </li>
            </ul>
          </div>
          <div className="tab-content" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home-tab-pane"
              role="tabpanel"
              aria-labelledby="home-tab"
              tabindex="0"
            >
              <div className="py-5 row">
                <div
                  className="py-5 col-lg-6 col-md-12 col-sm-12"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="500"
                >
                  <h4 className="py-2 text-right">خطوتك الأولى</h4>
                  <p className="fs text-right">
                    انضم إلى ResumeByte لبدء بناء سيرة ذاتية احترافية ومتوافقة
                    مع نظام تتبع المتقدمين (ATS). بضع نقرات فقط، أنشئ ملفك
                    الشخصي وادخل إلى أدوات بناء السيرة الذاتية المصممة لإنشاء
                    سيرة ذاتية سهلة ومخصصة.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 flex justify-center items-center">
                  <img
                    className="img vert-move w-[30vw] h-[30vh] object-contain"
                    alt=" "
                    src={signup}
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  />
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="profile-tab-pane"
              role="tabpanel"
              aria-labelledby="profile-tab"
              tabindex="0"
            >
              <div className="py-5 row">
                <div
                  className="py-5 col-lg-6 col-md-12 col-sm-12"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="500"
                >
                  <h4 className="py-2 text-right">خطوتك الثانية</h4>
                  <p className="fs text-right">
                    ابنِ سيرة ذاتية تبرز بين الآخرين. توجهك واجهتنا سهلة
                    الاستخدام خطوة بخطوة في تصميم سيرة ذاتية تتماشى مع متطلبات
                    ATS وتعرض مهاراتك وخبراتك بشكل فعال.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 flex justify-center items-center">
                  <img
                    className="img vert-move w-[30vw] h-[30vh] object-contain"
                    src={create}
                    alt=" "
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  />
                  {/* <BiEdit className={iconClasses} />, */}
                </div>
              </div>
            </div>
            <div
              className="tab-pane fade"
              id="contact-tab-pane"
              role="tabpanel"
              aria-labelledby="contact-tab"
              tabindex="0"
            >
              <div className="py-5 row">
                <div
                  className="py-5 col-lg-6 col-md-12 col-sm-12"
                  data-aos="fade-right"
                  data-aos-anchor="#example-anchor"
                  data-aos-offset="500"
                  data-aos-duration="500"
                >
                  <h4 className="py-2 text-right">خطوتك الثالثة</h4>
                  <p className="fs text-right">
                    مع سيرتك الذاتية جاهزة، قم بتنزيلها بتنسيق مُحسّن للتقديم
                    على الوظائف عبر الإنترنت. كن واثقًا من أن سيرتك الذاتية
                    منسقة بشكل مثالي لتجاوز أي نظام تتبع للمتقدمين وترك انطباع
                    لدى مديري التوظيف.
                  </p>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 flex justify-center items-center">
                  <img
                    className="img vert-move w-[30vw] h-[30vh] object-contain"
                    src={download}
                    alt=" "
                    data-aos="fade-left"
                    data-aos-anchor="#example-anchor"
                    data-aos-offset="500"
                    data-aos-duration="500"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProAcc;

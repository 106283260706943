import { AiFillStar } from "react-icons/ai";
import { MdArrowBackIos, MdArrowForwardIos } from "react-icons/md";

import Review from "./review";

const TestimonialsSection = () => {
  const reviews = [
    {
      heading: "",
      rating: 5,
      user: "Jeffery Jones",
      review:
        "لقد استخدمت العديد من مواقع بناء السير الذاتية في الماضي، لكن هذا الموقع هو الأفضل بكثير. خيارات التخصيص لا حصر لها، مما يتيح لي تخصيص سيرتي الذاتية لتناسب أي طلب وظيفة. عملية بناء السيرة الذاتية سهلة حقًا ولا أشعر أنني أبني سيرة ذاتية!",
    },
    {
      heading: "",
      rating: 5,
      user: "Victor Smith",
      review:
        "كنت مترددًا في البداية في استخدام منشئ السير الذاتية، لكن هذا الموقع فاق توقعاتي وجعل العمل سهلاً للغاية بفضل واجهته التفاعلية. القوالب جذابة بصريًا وتعرض مهاراتي وخبرتي بأفضل شكل ممكن. أنا سعيد جدًا لأنني وجدت هذا الموقع!",
    },
    {
      heading: "",
      rating: 5,
      user: "Rachel Ashley",
      review:
        "لا أستطيع أن أوصي بهذا الباني للسير الذاتية بما فيه الكفاية. الموقع سهل الاستخدام والقوالب قابلة للتخصيص واحترافية.أفضل ميزة وجدتها كانت 'التوافق مع ATS' التي لم تصنع سيرة ذاتية محسّنة فحسب، بل ساعدتني أيضًا في الحصول على وظيفة أحلامي. هذا الموقع هو نقطة تحول لأي شخص يسعى لتحسين بحثه عن وظيفة.",
    },
  ];
  const avgRating = 4;
  return (
    <div>
      <div className="flex justify-between gap-8 flex-col lg:flex-row">
        <div className="flex flex-col gap-2 lg:gap-6 items-center lg:items-start">
          <div className="flex flex-col gap-1 items-center lg:items-start">
            <p className="text-xl font-medium">{`${avgRating} out of 5`}</p>
            <div className="flex gap-0">
              {new Array(avgRating).fill("").map((_, i) => (
                <AiFillStar key={i} className={"text-blue-500 h-8 w-8"} />
              ))}
              {!!(5 - avgRating) &&
                new Array(5 - avgRating)
                  .fill("")
                  .map((_, i) => (
                    <AiFillStar className="text-slate-400 h-8 w-8" key={i} />
                  ))}
            </div>
          </div>
          <p className="text-xs text-slate-400">استنادًا إلى 44,112 مراجعة</p>
        </div>
        <div className="flex flex-col gap-7 overflow-hidden">
          <div className="overflow-hidden pb-4 scrollbar scrollbar-h-1 scrollbar-rounded-full scrollbar-track-slate-300 scrollbar-corner-rounded-full  scrollbar-thumb-blue-500">
            <div className="flex gap-4">
              {reviews.map(({ rating, user, review }, i) => (
                <Review key={i} rating={rating} user={user} review={review} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialsSection;

import React from "react";

function Faq() {
  return (
    <div className="container">
      <div className="row py-5">
        <h1 className="py-3 txt text-6xl">الأسئلة المتكررة!</h1>
        <div
          className="accordion accordion-flush greybg p-2"
          id="accordionFlushExample"
        >
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseOne"
                aria-expanded="false"
                aria-controls="flush-collapseOne"
              >
                Q: من سيكتب ورقتي؟
              </button>
            </h2>
            <div
              id="flush-collapseOne"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingOne"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: لدينا فريق من الكتاب الخبراء ذوي المعرفة المتخصصة والخبرة في
                الكتابة الأكاديمية. الكاتب المخصص لمشروعك سيكون لديه خلفية في
                مجال دراستك والمهارات اللازمة لتقديم عمل عالي الجودة.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseTwo"
                aria-expanded="false"
                aria-controls="flush-collapseTwo"
              >
                Q : هل ستكون ورقتي أصلية؟
              </button>
            </h2>
            <div
              id="flush-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingTwo"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: نعم، جميع أوراقنا مضمونة أن تكون أصلية بنسبة 100% وخالية من
                الانتحال. نستخدم أدوات وتقنيات متقدمة لفحص الانتحال لضمان أن
                عملك فريد وأصلي.
              </div>
            </div>
          </div>
          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapseThree"
                aria-expanded="false"
                aria-controls="flush-collapseThree"
              >
                Q: كم سيكلف كتابة ورقتي؟
              </button>
            </h2>
            <div
              id="flush-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingThree"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: ستعتمد تكلفة ورقتك على عدد من العوامل، بما في ذلك نوع الورقة،
                الطول، والمواعيد النهائية. نحن نقدم خيارات تسعير مرنة تناسب
                ميزانيتك واحتياجاتك.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingfour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefour"
                aria-expanded="false"
                aria-controls="flush-collapsefour"
              >
                Q: ماذا لو لم أكن راضيًا عن الورقة؟
              </button>
            </h2>
            <div
              id="flush-collapsefour"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfour"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: نسعى جاهدين لتقديم عمل عالي الجودة يلبي متطلباتك ويتجاوز
                توقعاتك. إذا لم تكن راضيًا عن الورقة لأي سبب من الأسباب، فنحن
                نقدم تعديلات مجانية حتى تكون راضيًا تمامًا.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingfive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsefive"
                aria-expanded="false"
                aria-controls="flush-collapsefive"
              >
                Q: كيف أقدم طلبًا؟
              </button>
            </h2>
            <div
              id="flush-collapsefive"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingfive"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: إجراء الطلب سهل. ما عليك سوى ملء نموذج الطلب على موقعنا
                الإلكتروني، وتقديم جميع التفاصيل اللازمة حول مشروعك. يمكنك أيضًا
                الاتصال بفريق دعم العملاء لدينا للحصول على المساعدة في تقديم
                طلبك.
              </div>
            </div>
          </div>

          <div className="accordion-item">
            <h2 className="accordion-header" id="flush-headingsix">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#flush-collapsesix"
                aria-expanded="false"
                aria-controls="flush-collapsesix"
              >
                Q: ماذا لو كنت بحاجة إلى ورقتي بشكل عاجل؟
              </button>
            </h2>
            <div
              id="flush-collapsesix"
              className="accordion-collapse collapse"
              aria-labelledby="flush-headingsix"
              data-bs-parent="#accordionFlushExample"
            >
              <div className="accordion-body">
                A: نحن نفهم أن المواعيد النهائية قد تكون ضيقة، ولهذا السبب نقدم
                خيارات توصيل مرنة، بما في ذلك الطلبات العاجلة. اتصل بنا لمناقشة
                احتياجاتك وسنبذل قصارى جهدنا لتلبية موعدك النهائي.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Faq;

import React,{useEffect} from "react";
import { Link } from "react-router-dom";
import "./ResumeEx.css";
import imge from "../../images/template4.png";
import AOS from "aos";
import "aos/dist/aos.css";
function ResumeEx() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="backfooter">
      <div className="container ">
        <div className="py-5 row">
          <div
            className="pt-4 col-lg-6 col-md-12 col-sm-12"
            data-aos="zoom-in-right"
          >
            <p className="text-right">ابدأ ببناء مسيرتك المهنية</p>
            <h2 className="text-right text-4xl">
              السير الذاتية المهنية للمقابلات الوظيفية الفعالة
            </h2>
            <p className="text-right text-lg my-[2vmax]">
              طلب وظيفة رائع يؤدي إلى مقابلة جيدة. سيرة ذاتية مذهلة هي ما يجعل
              كل ذلك ممكنًا. ابدأ بقوة مع مدير التوظيف من خلال خلق صورة مهنية
              إيجابية. يمكن أن تكون مقابلة العمل أسهل بكثير إذا كانت لديهم نظرة
              إيجابية تجاه سيرتك الذاتية ورسالة التغطية الخاصة بك.
            </p>
            <button className="p-3 text-white bluebg ml-[25.5vw]">
              <Link to="/build-resume" className="text-white">
                ابدأ الآن
              </Link>
            </button>
            &nbsp; &nbsp; &nbsp;
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                أمثلة على السير الذاتية
              </Link>
            </button>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center"
            data-aos="zoom-in-left"
          >
            <img
              src={imge}
              alt="banner"
              height="50% !important"
              className="imge pt-lg-0 pt-md-5 pt-sm-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ResumeEx;

import BlueDash from "../blue-dash";
import Heading from "../heading";
import { Link } from "react-router-dom";
// import TemplateCarousel from "./template-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import template1 from "./../../images/template1.jpg";
import template2 from "./../../images/template2.jpg";
import template3 from "./../../images/template3.jpg";
const TemplatesSection = () => {
  return (
    <div className="">
      <div className="container">
        <div className="py-5 row ">
          <div className="pt-4 col-lg-6 col-md-12 col-sm-12">
            <h1 className="head1 text-right w-[30vmax]">
              نماذج سيرة ذاتية جاهزة وجميلة للاستخدام
            </h1>
            <BlueDash classname="mt-10 ml-[28vmax]" />
            <p className="text-right">
              افزِر على أصحاب العمل والم المجندون باستخدام أحد قوالب السيرة
              الذاتية الأنيقة والمصممة بشكل احترافي من بين أكثر من 25 قالبًا
              لدينا. تحميل إلى Word أو PDF.
            </p>
            <button className="p-3 text-white bluebg ml-[25vmax]">
              <Link to="/build-resume" className="text-white">
                اختر القالب
              </Link>
            </button>
          </div>
          <div className="pt-3 col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center">
            <Carousel infiniteloop>
              <div>
                <img src={template1} alt="" />
              </div>
              <div>
                <img src={template2} alt="" />
              </div>
              <div>
                <img src={template3} alt="" />
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TemplatesSection;

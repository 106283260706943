import React, { useEffect } from "react";
import "./Feature.css";

import builder from "../../images/features/bulder.jpeg";
import cover from "../../images/features/cover.png";
import datasave from "../../images/features/datasave.jpg";
import interview from "../../images/features/interview.png";
import job from "../../images/features/job.png";
import resume from "../../images/features/resume.png";
import spell from "../../images/features/spell.png";
import summary from "../../images/features/sumary.png";
import template from "../../images/features/template.png";
import AOS from "aos";
import "aos/dist/aos.css";
function Feature() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="feature" id="features">
      <div className="container">
        <div className="py-5 row">
          <h3 className="py-5 txt">
            ميزات مصممة لمساعدتك في الحصول على وظيفة أحلامك
          </h3>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12 "
              data-aos="zoom-in-down"
            >
              <img src={builder} alt="f1" className="center vert-move" />
              <h5>منشئ سيرة ذاتية عبر الإنترنت بسهولة</h5>
              <p className="text-right">
                أنشئ سيرة ذاتية رائعة في دقائق، دون مغادرة متصفح الويب الخاص بك.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={spell} alt="f1" className="center vert-move" />
              <h5>مدقق إملائي تلقائي</h5>
              <p className="text-right">
                مدقق الإملاء المدمج لدينا يعتني بالقواعد النحوية من أجلك. أنشئ
                سيرة ذاتية خالية من الأخطاء أو الأخطاء المطبعية.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={datasave} alt="f1" className="center vert-move" />
              <h5>بياناتك آمنة</h5>
              <p className="text-right">
                يتم الاحتفاظ ببياناتك خاصة ومحمية بتشفير قوي 256 بت.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={summary} alt="f1" className="center vert-move" />
              <h5>مولد ملخص تلقائي</h5>
              <p>
                أنشئ ملف سيرة ذاتية قوي أو خطاب تغطية بنقرة واحدة. لم يعد انسداد
                الكاتب عقبة. جرب مجانًا!
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={template} alt="f1" className="center vert-move" />
              <h5>القوالب المعتمدة</h5>
              <p>
                قوالب سيرة ذاتية مصممة احترافيًا وأمثلة (+أدلة). ما عليك سوى
                التعديل والتنزيل في 5 دقائق.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={job} alt="f1" className="center vert-move" />
              <h5>تتبع الوظائف</h5>
              <p>
                سنساعدك على التفوق على المنافسة من خلال تتبع أصحاب العمل
                والوظائف التي تتقدم لها.
              </p>
            </div>
          </div>
          <div className="row">
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={interview} alt="f1" className="center vert-move" />
              <h5>مدرسة المقابلة</h5>
              <p>
                أداتنا الرقمية الفريدة ستساعدك في الفوز بالوظائف! تلقَّ
                التعليقات والنصائح وحسِّن مهاراتك في المقابلات.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={resume} alt="f1" className="center vert-move" />
              <h5>خيارات السيرة الذاتية متعددة التنسيقات</h5>
              <p>
                احفظ سيرتك الذاتية المثالية بأي تنسيق شائع، بما في ذلك Microsoft
                Word وPDF بنقرة واحدة فقط.
              </p>
            </div>
            <div
              className="col-lg-4 col-md-4 col-sm-12"
              data-aos="zoom-in-down"
            >
              <img src={cover} alt="f1" className="center vert-move" />
              <h5>خطابات التغطية</h5>
              <p>
                يعمل منشئ خطاب التغطية لدينا بنفس سهولة واستخدام القوالب الأنيقة
                مثل منشئ السيرة الذاتية.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Feature;

import BlueDash from "../blue-dash";
import Heading from "../heading";

const ContactUsHeading = () => {
  return (
    <div>
      <div className="flex flex-col items-center gap-2 py-5 lg:items-start lg:pr-44">
        <h1 className="head5 ">اتصل بنا</h1>
        <BlueDash marginY="my-2" />
        <p className="whitespace-nowrap">
          <span className="font-medium">البريد الإلكتروني:</span>{" "}
          resumebyte.beta@gmail.com
        </p>
      </div>
      <p className="mt-7 ">
        هل تواجه مشكلة؟ ابحث عن إجابة استفسارك هنا. لا تتردد في الاتصال بنا!
      </p>
    </div>
  );
};

export default ContactUsHeading;

import React from "react";
import "./Cvex.css";
import { Link } from "react-router-dom";
function Cvex() {
  return (
    <div className="backfooter">
      <div className="container ">
        <div className="py-5 row txt d-flex justify-content-center">
          <div className="pt-4 col-lg-8 col-md-12 col-sm-12">
            <h1 className="text-right font-bold">
              سهل الاستخدام. مهني. فعال. جرّب منشئ خطاب التقديم الخاص بنا اليوم!
            </h1>
            <p className="text-right">
              كم من الوقت يستغرق كتابة خطاب التغطية؟ ساعات؟ أيام؟ مع صانع خطاب
              التغطية الخاص بنا يمكنك الانتهاء في دقائق. أنشئ رسالة طلب مقنعة
              وفعالة في بضع نقرات. استخدم العبارات التي تم إنشاؤها مسبقًا، اختر
              تصميمًا، واملأ تفاصيلك وأفكارك. سريع وبسيط.
            </p>
            <button className="p-3 text-white bluebg ml-[35vw]">
              <Link to="/build-resume" className="text-white">
                جربه الآن
              </Link>
            </button>
            &nbsp; &nbsp; &nbsp;
            <button className="p-3 text-white bluebg">
              <Link to="/build-resume" className="text-white">
                أمثلة على خطابات التغطية
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Cvex;

import { useState, useEffect } from "react";
import resume1 from "../images/resume1.jpeg";
import resume2 from "../images/resume2.jpg";
import resume3 from "../images/resume3.jpg";
import Testimonials from "./testimonials";
import ServiceBox from "../Components/service/service";
import AboutUs from "./about-us";
import Guide from "../Components/guide/Guide";
import Templates from "./templates";
import ResumeShortlistedBy from "./resume-shortlisted-by";
import Createcover from "../Components/createcover/Createcover";
import Cvex from "../Components/CvEx/Cvex";
import Feature from "../Components/Features/Feature";
import ResumeEx from "../Components/ResumeExample/ResumeEx";
import Process from "./process";
// import Blog from "./blog";
import Faq from "../Components/faq/Faq";
import Join from "../Components/Join/Join";
import ContactUs from "./contact-us";
import { Link } from "react-router-dom";
import "../index.css";
import halftemp from "../images/halftemp.jpg";
import AOS from "aos";
import "aos/dist/aos.css";
const backgroundImageUrls = [resume1, resume2, resume3];
const HomeCarousel = () => {
  const [backgroundIndex, setBackgroundIndex] = useState(0);
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  // window.scrollTo(0, 0);

  useEffect(() => {
    // Set an interval to change the background image every 2 seconds
    const intervalId = setInterval(() => {
      setBackgroundIndex(
        (prevIndex) => (prevIndex + 1) % backgroundImageUrls.length
      );
    }, 2000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);

  const backgroundImageUrl = backgroundImageUrls[backgroundIndex];

  // tanmay work
  const carouselData = [
    {
      key: 1,
      heading:
        "تستخدم 98% من شركات فورتشن 500 نظام تتبع المتقدمين (ATS) لتصفية السير الذاتية.",
      headingBy: "Job scan",
      image: resume1,
      imgName: "resume1-img",
    },
    {
      key: 2,
      heading:
        "يقضي مدير التوظيف في المتوسط 7.4 ثوانٍ فقط في مراجعة السيرة الذاتية.",
      headingBy: "Forbes",
      image: resume2,
      imgName: "resume2-img",
    },
    {
      key: 3,
      heading:
        "في المتوسط، يتم رفض 75% من السير الذاتية بواسطة نظام تتبع المتقدمين (ATS) قبل أن تصل إلى أيدي مديري التوظيف.",
      headingBy: "Forbes",
      image: resume3,
      imgName: "resume3-img",
    },
  ];

  const styles = {
    height: "100vh",
    backgroundImage: `url(${backgroundImageUrl})`,
    //  backgroundColor:'#000000c9',
    // backgroundColor:"rgb(239, 246, 255)" ,
    // backgroundImage: "linear-gradient(to right, #ffc8dd, #b295f7)",
    // backgroundImage: "linear-gradient(120deg, #a1c4fd 0%, #c2e9fb 100%)",
    // backgroundColor: "#eff6ff",
    // backgroundImage:`url("../images/videoback.mp4")`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    transition: "all 700ms ease-in-out",
    zIndex: "-1",
    marginTop: "57px",
  };

  return (
    <>
      <div style={styles} id="home">
        {/* {carouselData.map((item, index) => (
                <div
                  key={index}
                  style={{
                    width:"100%",
                    height:"100%",
                    display:"flex",
                    flexDirection:"column",
                    justifyContent:"center",
                    alignItems:"center",
                    zIndex:"10"
                  }}
                >
                  <h2 style={{ fontSize: "5rem", marginBottom: "1rem" , zIndex:"11", color:"red"}}>
                    {item.heading}
                  </h2>
                  <p>{item.headingBy}</p>
                </div>
              ))} */}

        <div
          className="text-black "
          // className=" bg-[#252525b1] text-white"
          style={{
            // backgroundImage:`url("../images/videoback.mp4")`,
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            transition: "all 1s",
            backgroundColor: "#eff6ffc9",
            // zIndex: "10",
          }}
        >
          {/* <video autoPlay muted loop id='video'>
          <source src="../images/videoback.mp4" type="video/mp4"></source>
        </video> */}
          <p className="pt-5 text-center text-lg" data-aos="fade-down">
            منشئ السيرة الذاتية عبر الإنترنت
          </p>
          <h2
            data-aos="zoom-in"
            className={"italic text-2xl lg:text-4xl text-black-500 pt-2 head"}
            style={{
              fontSize: "2.0rem",
              marginBottom: "1rem",
              zIndex: "11",
              // justifyContent: "center",
              width: "100%",
              textAlign: "right",
              transition: "all 1s ease-in-out",
            }}
          >
            {carouselData[backgroundIndex].heading}
          </h2>
          <p className="text-lg text-left">
            {" "}
            ~ {carouselData[backgroundIndex].headingBy}
          </p>
          <p className="py-2 text-lg text-center" data-aos="zoom-in">
            قم بإنشاء سيرة ذاتية ومؤهلات عمل مثالية في خمس دقائق فقط
          </p>
          <button className="p-3 text-white bluebg">
            <Link to="/build-resume" className="text-white">
              أنشئ سيرتي الذاتية
            </Link>
          </button>
          <p className="pt-3 pb-2 text-lg text-center" data-aos="zoom-in">
            30,037 سيرة ذاتية تم إنشاؤها اليوم
          </p>
          {/* <img data-aos="flip-up"
          src={halftemp}
          alt="template"
          style={{
            transition: "all 1s ease-in-out",
          }} /> */}
        </div>
      </div>
      <Testimonials />
      <ServiceBox />
      <AboutUs />
      <Guide />
      <Templates />
      <ResumeShortlistedBy />
      <Createcover />
      <Cvex />
      <Feature />
      <ResumeEx />
      <Process />
      {/* <Blog /> */}
      <Faq />
      <Join />
      <ContactUs />
    </>
  );
};

export default HomeCarousel;

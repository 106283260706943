import React, { useEffect } from "react";
import "./createcover.css";
import AOS from "aos";
import "aos/dist/aos.css";
import { Link } from "react-router-dom";
import imge from "../../images/Checklist.png";
function Createcover() {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);
  return (
    <div className="">
      <div className="container ">
        <div className="py-5 row">
          <div
            className="pt-4 col-lg-6 col-md-12 col-sm-12"
            data-aos="zoom-in-right"
          >
            <p className="text-right">احصل على وظيفة أحلامك</p>
            <h2 className="text-right font-bold">
              أنشئ قصة احترافية في دقائق. استخدم صانع خطاب التقديم الخاص بنا.
            </h2>
            <p className="text-right text-lg">
              يسمح لك صانع خطاب التغطية لدينا بكتابة عروض احترافية مذهلة في
              دقائق بدلاً من ساعات. لا مزيد من انسداد الكتابة، لا مزيد من البحث
              عن العبارات المقنعة أو المعاناة من التنسيق. كن مقنعًا دون جهد!
            </p>

            <button className="p-3 text-white bluebg ml-[33vw]">
              <Link to="/build-resume" className="text-white">
                إنشاء خطاب تغطية
              </Link>
            </button>
          </div>
          <div
            className="col-lg-6 col-md-12 col-sm-12 d-flex justify-content-center"
            data-aos="zoom-in-left"
          >
            <img
              src={imge}
              alt="banner"
              height="50% !important"
              className="imge pt-lg-0 pt-md-5 pt-sm-5"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Createcover;

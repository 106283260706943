import { useEffect } from "react";
import optimise from "../../images/image-removebg-preview (2).png";
import ats from "../../images/ats.png";
import custom from "../../images/custom.png";
import AOS from "aos";
import "aos/dist/aos.css";
const Service = () => {
  useEffect(() => {
    AOS.init({ duration: 1200 });
  }, []);

  return (
    <div class="container px-2 py-5  mx-auto text-gray-600 body-font">
      <div class="flex flex-wrap -m-4 py-5 justify-content-center">
        <div class="xl:w-1/3 md:w-1/2 p-4" data-aos="fade-right">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={optimise}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-[1.3vmax] font-medium title-font text-right">
              تحسين السيرة الذاتية
            </h3>
            <p class="leading-relaxed text-base text-right">
              تقوم تقنيتنا المتطورة بتحليل سيرتك الذاتية لضمان أنها مُحسّنة
              لنظام تتبع المتقدمين (ATS). نستخدم خوارزميات متقدمة لتحديد الكلمات
              الرئيسية والعبارات الأكثر صلة بوظيفتك المستهدفة ونتأكد من أن سيرتك
              الذاتية مصممة بطريقة يسهل على نظام تتبع المتقدمين (ATS) قراءتها.
            </p>
          </div>
        </div>
        <div class="xl:w-1/3 md:w-1/2 p-4" data-aos="fade-up">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={ats}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-[1.3vmax] text-right font-medium title-font">
              توافق نظام تتبع المتقدمين
            </h3>
            {/* <h2 class="text-lg text-gray-900 font-medium title-font mb-4">San Francisco</h2> */}
            <p class="leading-relaxed text-base text-right">
              نحن نضمن أن سيرتك الذاتية متوافقة مع جميع أنظمة تتبع المتقدمين
              الرئيسية المستخدمة من قبل أصحاب العمل. هذا يعني أن سيرتك الذاتية
              ستكون قابلة للبحث والوصول بسهولة من قبل المجندين ومديري التوظيف،
              مما يزيد من فرصك في أن تُلاحظ.
            </p>
          </div>
        </div>
        <div class="xl:w-1/3 md:w-1/2 p-4 " data-aos="fade-left">
          <div
            class=" p-6 rounded-lg grid"
            style={{ backgroundColor: "#eff6ff" }}
          >
            <img
              class="h-40 rounded w-full object-cover object-center mb-6"
              src={custom}
              alt="content"
              className="h-auto max-w-[50%] justify-self-center vert-move"
            />
            <h3 class="tracking-widest text-indigo-500 text-[1.3vmax] text-right font-medium title-font">
              التخصيص
            </h3>
            <p class="leading-relaxed text-base text-right">
              نقدم خدمات مخصصة لتكييف سيرتك الذاتية مع الوظيفة المحددة التي
              تتقدم لها. فريقنا من كتّاب السير الذاتية الخبراء سيعمل معك لتسليط
              الضوء على نقاط قوتك وإنجازاتك بطريقة تتناسب مع أصحاب العمل
              المحتملين.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;

import React, { useRef, useState } from "react";

// Validation function
const valid = (field, value) => {
  // Common fields that just require more than 1 character
  const textFields = [
    "Name",
    "Summary",
    "School",
    "Degree",
    "Discipline",
    "Certification Name",
    "Awarding Organization",
    "Title",
    "Organization",
    "Position",
    "Description",
    "Role",
  ];

  if (textFields.includes(field)) {
    return value.length > 1;
  }

  // Email validation
  if (field === "Email") {
    return value.includes("@");
  }

  // URL validation for Github, Linkedin, or any other link
  if (["Github", "Linkedin", "Link"].includes(field)) {
    // Regular expression to validate URL format
    const urlPattern = /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*$/i;
    return value === "" || urlPattern.test(value); // Accept empty string or valid URL
  }

  // Mobile number validation (ensure it's numeric)
  if (field === "Mobile") {
    return !isNaN(value) && value.length === 10; // Assuming mobile number should have 10 digits
  }

  // Fields that don't require specific validation
  if (["Skills", "Hobbies", "Start Date", "End Date"].includes(field)) {
    return true;
  }

  // Marks validation (must be numeric)
  if (field === "Marks") {
    return !isNaN(value);
  }

  // Default: Invalid if none of the above conditions are met
  return false;
};

export default function InputControl(props) {
  const inputRef = useRef();
  const [correct, setCorrect] = useState(true);

  const handleInputChange = () => {
    const input = inputRef.current.value.trim(); // Trim whitespace
    if (!valid(props.field, input)) {
      setCorrect(false);
      props.set(props.isComp ? false : ""); // Pass error state if invalid
    } else {
      setCorrect(true);
      props.set(input); // Pass valid input to parent component
    }
  };

  return (
    <div className="w-full h-full flex flex-col justify-between">
      {/* Label section */}
      <div
        className={`text-sm rounded-md flex items-center px-4 ${
          props.type === "textarea" ? "my-1" : ""
        }`}
      >
        <div className="text-black-600 ">{props.field || "field"}</div>
        <div className="text-red-600">{props.isComp ? "*" : ""}</div>
      </div>

      {/* Input section */}
      <div className="w-[100%] h-[80%] z-1 flex flex-row justify-center items-center">
        {props.type === "textarea" ? (
          <textarea
            rows="4"
            placeholder={props.placeholder || "placeholder"}
            className={`w-full border-2 h-full bg-white text-right ${
              correct
                ? "border-blue-300"
                : "border-red-500 active:border-6 my-2"
            } focus:outline-none rounded-md p-2`}
            ref={inputRef}
            onBlur={handleInputChange}
          />
        ) : (
          <input
            type={props.type || "text"}
            placeholder={props.placeholder || "placeholder"}
            className={`w-full border-2 bg-white text-right ${
              correct ? "border-blue-300" : "border-red-500 active:border-6"
            } focus:outline-none rounded-md p-2`}
            ref={inputRef}
            onBlur={handleInputChange}
          />
        )}
      </div>

      {/* Error message */}
      <div className="h-[20%] px-4 text-[12px] text-red-400">
        {!correct && props.errmsg}
      </div>
    </div>
  );
}
